import React from "react";
import Particles from "react-tsparticles";

function Particle() {
  return (
    <Particles
      id="tsparticles"
      params={{
        particles: {
          size: { value: 1.6 },
          zIndex: -2,
          number: {
            value: 100,
            density: {
              enable: false,
              value_area: 1000,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.2,
            width: 0.6,
          },
          move: {
            enable: true,
            speed: 0.4,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: true,
        }
      },
      size: {
        value: 1,
      },

          opacity: {
            value: 0.2,
            random: false,
            animation: {
                enable: true,
                speed: 0.4,
                opacity_max: 0.3,
                sync: true
            }
        },
        interactivity: {
          events: {
            onHover:{
              enable: true,
              mode:"push"
            },
            onclick: {
              enable: true,
              mode: "push",
            },
          },
          modes: {
            repulse: {
              distance: 100,
              duration: 0.4
            },
            push: {
              particles_nb: 2
            }
          }
        },
        retina_detect: true,
      }}
    />
  );
}


export default Particle;