import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Sudoku from "../../Assets/projects/Sudoku.png";
import Gapi from "../../Assets/projects/gapi.png";
import Soon from "../../Assets/projects/soon.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Some Things I've <strong className="purple">Made </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Sudoku}
              isBlog={false}
              title="Sudoku Solver"
              description="A simple Sudoku solver I made in class. My girlfriend loves Sudoku and I thought why not make a Sudoku solver using her passion as an idea!"
              ghLink= "https://github.com/Sp0ngeman/Sudoku-Solver"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Gapi}
              isBlog={false}
              title="Github API"
              description="Using a free Github API, I made this project with the purpose of giving users the ability to search up any user on Github and see their information."
              ghLink="https://github.com/Sp0ngeman/GitHub-Api"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
            imgPath={Soon}
            isBlog={false}
              title='More Coming Soon!'
              description="I am always trying to improve everything I do, and I do this by trying to make and improve new things. So stay tuned! "             
              ghLink="https://github.com/Sp0ngeman"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;