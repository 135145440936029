import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { CgGitFork } from "react-icons/cg";
import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
} from "react-icons/ai";
import { CgFileDocument } from "react-icons/cg";

function NavBar() {
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    function handleScroll() {
      setIsSticky(window.scrollY >= 100);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleMenuButtonClick() {
    setIsMenuOpen(!isMenuOpen);
  }

  function handleLinkClick() {
    setIsMenuOpen(false);
  }

  return (
    <Navbar
      fixed="top"
      expand="md"
      className={isSticky ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={handleLinkClick}>
          <img src={logo} className="img-fluid logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={handleMenuButtonClick}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={isMenuOpen ? "show" : ""}
        >
          <Nav className="ms-auto align-items-center" defaultActiveKey="#home">
            <Nav.Item className="mx-3">
              <Nav.Link
                as={Link}
                to="/"
                onClick={handleLinkClick}
                className="nav-link"
              >
                <AiOutlineHome /> Home
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="mx-3">
              <Nav.Link
                as={Link}
                to="/about"
                onClick={handleLinkClick}
                className="nav-link"
              >
                <AiOutlineUser /> About
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="mx-3">
              <Nav.Link
                as={Link}
                to="/project"
                onClick={handleLinkClick}
                className="nav-link"
              >
                <AiOutlineFundProjectionScreen /> Projects
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="mx-3">
              <Nav.Link
                as={Link}
                to="/resume"
                onClick={handleLinkClick}
                className="nav-link"
              >
                <CgFileDocument /> Resume
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="mx-3">
              <Button
                href="https://github.com/Sp0ngeman"
                target="_blank"
                className="fork-btn-inner"
                onClick={handleLinkClick}
              >
                <CgGitFork /> <AiFillStar />
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
